import $ from 'jquery';

// 特定のmeeting_idが選択された際に、学会名のセレクトボックスを表示する
document.addEventListener('DOMContentLoaded', function () {
  const specificMeetingIds = [4, 5, 10];
  const specificMeetings = [6,7];
  const specificMeetingId = 11;
  const conferenceSelect = document.querySelector('.form-control.form-select.conference-select'); 
  const conferenceCustomSelect = document.querySelector('.form-control.form-select.conference-custom-select');
  
  function initializeDropdowns() {
    const meetingSelect = document.querySelector('.form-check-input:checked');
    const initialMeetingId = meetingSelect ? parseInt(meetingSelect.value, 10) : null;
    const initialConferenceId = conferenceSelect.dataset.selectedId;
    const conferenceNestDropdown = document.getElementById('conference-nest-dropdown');
    const initialConferenceCustomId = conferenceCustomSelect.dataset.selectedId;
    const nestedConferenceSelect = document.getElementById('nested_conference_select');
    const initialNestConferenceId = nestedConferenceSelect.dataset.selectedId;
    const trainingSelect = document.getElementById('training_select');
    const initialTrainingId = trainingSelect.dataset.selectedId;

    if (initialMeetingId) {
      if (specificMeetingIds.includes(initialMeetingId)) { // meeting_id = 4,5,10
        showAllConferences().then(() => {
          $(conferenceSelect).val(initialConferenceId);
          document.getElementById('additional-dropdown').style.display = 'block';
          resets({ conferenceCustoms:true, nestConferencs: true, trainings: true, subtitleShow: true });
        });
      } else if (initialMeetingId === specificMeetingId) { // meeting_id = 11
        updateConferencesForSpecificMeeting().then(() => {
          $(conferenceCustomSelect).val(initialConferenceCustomId);
          if (initialNestConferenceId) {
            updateNestedConferences(initialConferenceCustomId).then(() => {
              $(nestedConferenceSelect).val(initialNestConferenceId);
              $('#nested_conference_select').closest('.form-check').show();
              resets({ conferences: true, trainings: true, subtitle: true });
            });
          }
          conferenceNestDropdown.style.display = 'block';
          $('#nested-conference-dropdown').show();
        });
      } else if (specificMeetings.includes(initialMeetingId)) { // meeting_id = 6,7
        showAllTrainings().then(() => {
          $(trainingSelect).val(initialTrainingId);
          document.getElementById('nest-trainings-dropdown').style.display = 'block';
          resets({ conferenceCustoms:true, nestConferencs: true, conferences: true, subtitle: true });
        });
      }
    }
  }

  initializeDropdowns();

  document.querySelectorAll('.form-check-input').forEach(function (radioButton) {
    radioButton.addEventListener('change', function () {
      const selectedMeetingId = parseInt(this.value);
      if (this.checked && specificMeetingIds.includes(selectedMeetingId)) { // meeting_id = 4,5,10
        showAllConferences().then(() => {
          resets({ conferenceCustoms:true, nestConferencs: true, trainings: true, subtitleShow: true });
        });
      } else if (this.checked && selectedMeetingId === specificMeetingId) { // meeting_id = 11
        resets({ conferences: true, trainings: true, subtitle: true });
        updateConferencesForSpecificMeeting();
      } else if (this.checked && specificMeetings.includes(selectedMeetingId)) { // meeting_id = 6,7
        showAllTrainings().then(() => {
          $('#nest-trainings-dropdown').show();
          resets({ conferenceCustoms:true, nestConferencs: true, conferences: true, subtitle: true });
        });
      } else {
        resets({ conferences: true, conferenceCustoms:true, nestConferencs: true, trainings: true, subtitleShow: true });
      }
    });
  });

  // 全conferenceデータを取得する(meeting_id = 4,5,10)
  function showAllConferences() {
    return $.ajax({
      url: '/users/conferences',
      method: 'GET',
      dataType: 'json',
      success: function(data) {
        let options = '<option value="">選択してください</option>';
        data.forEach(function(conference) {
          options += `<option value="${conference.id}">${conference.name}</option>`;
        });
        $(conferenceSelect).html(options);
        document.getElementById('additional-dropdown').style.display = 'block';
        conferenceSelect.required = true;
      },
      error: function() {
        $('#flash-messages').html('<div class="alert alert-danger">エラーが発生しました。</div>');
      }
    });
  }

  // nest_conferenceにデータがあるconferenceデータのみ取得する(meeting_id = 11)
  function updateConferencesForSpecificMeeting() {
    return $.ajax({
      url: `/users/conferences/conferences_with_nested`,
      method: 'GET',
      dataType: 'json',
      success: function(data) {
        let options = '<option value="">選択してください</option>';
        data.forEach(function(conference) {
          options += `<option value="${conference.id}">${conference.name}</option>`;
        });
        $(conferenceCustomSelect).html(options);
        document.getElementById('conference-nest-dropdown').style.display = 'block';
        conferenceCustomSelect.required = true;
        $('#nested-conference-dropdown').show(); 
        $('#nested_conference_select').attr('required', true);
        $('#meeting_2_title').hide().removeAttr('required');
      },
      error: function() {
        $('#flash-messages').html('<div class="alert alert-danger">エラーが発生しました。</div>');
      }
    });
  }

  $(conferenceCustomSelect).change(function() {
    const selectedConferenceId = $(this).val();
    updateNestedConferences(selectedConferenceId);
  });

  // conference.idに紐づいたnest_conference_idを取得する(meeting_id = 11)
  function updateNestedConferences(conferenceId) {
    return $.ajax({
      url: `/users/conferences/${conferenceId}/nested_conferences`,
      method: 'GET',
      dataType: 'json',
      success: function(data) {
        let nestedOptions = '<option value="">選択してください</option>';
        data.forEach(function(nested) {
          nestedOptions += `<option value="${nested.id}">${nested.name}</option>`;
        });
        $('#nested_conference_select').html(nestedOptions);
        $('#nested_conference_select').closest('.form-check').show();
      },
      error: function() {
        $('#flash-messages').html('<div class="alert alert-danger">エラーが発生しました。</div>');
      }
    });
  }

  // 全nest_meeting_trainingsデータを取得する(meeting_id = 6,7)
  function showAllTrainings() {
    return $.ajax({
      url: '/users/meetings/all_trainings',
      method: 'GET',
      dataType: 'json',
      success: function(data) {
        let options = '<option value="">選択してください</option>';
        data.forEach(function(trainings) {
          options += `<option value="${trainings.id}">${trainings.name}</option>`;
        });
        $('#training_select').html(options);
        $('#nest-trainings-dropdown .form-check').show();
        $('#training_select').attr('required', true);
      },
      error: function() {
        $('#flash-messages').html('<div class="alert alert-danger">エラーが発生しました。</div>');
      }
    });
  }

  // meeting_id毎のリセット内容
  function resets(options) {
    // subtitleフィールドを入力必須解除 && 非表示する
    if (options.subtitle) {
      $('#meeting_2_title').hide().removeAttr('required').val('');
      $('#title_field').hide();
    }
    // id = additional-dropdownを非表示にする && conference-selectのrequiredを解除する
    if (options.conferences) {
      $('#additional-dropdown').hide();
      conferenceSelect.value = '';
      conferenceSelect.required = false;
    }
    // id = conference-nest-dropdownを非表示にする && conference-custom-selectのrequiredを解除する
    if (options.conferenceCustoms) {
      $('#conference-nest-dropdown').hide();
      conferenceCustomSelect.value = '';
      conferenceCustomSelect.required = false;
    }
    // id = nested-conference-dropdown非表示にする && nested_conference_selectを入力解除する
    if (options.nestConferencs) {
      $('#nested_conference_select').val('');
      $('#nested_conference_select').closest('.form-check').hide();
      $('#nested-conference-dropdown').hide();
      $('#nested_conference_select').removeAttr('required');
    }
    // id = nest-trainings-dropdown非表示にする && training_selectを入力解除する
    if (options.trainings) {
      $('#training_select').val('');
      $('#training_select').closest('.form-check').hide();
      $('#nest-trainings-dropdown').hide();
      $('#training_select').removeAttr('required');
    }
    // subtitleフィールドを入力必須 && 表示する
    if (options.subtitleShow) {
      $('#meeting_2_title').show().attr('required', 'required');
      $('#title_field').show();
    }
  }
});